import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { store } from './store';
import { AuthProvider } from './context/AuthContext';
import { WishlistProvider } from './context/WishlistContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Shop from './pages/Shop';
import Category from './pages/Category';
import ProductDetail from './pages/ProductDetail';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import JewelryCare from './pages/JewelryCare';
import SizingGuide from './pages/SizingGuide';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Account from './pages/Account';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import TermsOfService from './pages/TermsOfService';
import JewelryEducation from './pages/education/JewelryEducation';
import CustomRing from './pages/CustomRing';
import Materials from './pages/Materials';
import Accessories from './pages/Accessories';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a6741',
    },
    secondary: {
      main: '#c0a080',
    },
  },
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <WishlistProvider>
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/category/:categoryName" element={<Category />} />
                <Route path="/product/:productId" element={<ProductDetail />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:postId" element={<BlogPost />} />
                <Route path="/our-story" element={<AboutUs />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/jewelry-care" element={<JewelryCare />} />
                <Route path="/sizing-guide" element={<SizingGuide />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/account" element={<Account />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/jewelry-education" element={<JewelryEducation />} />
                <Route path="/custom-ring" element={<CustomRing />} />
                <Route path="/materials" element={<Materials />} />
                <Route path="/accessories" element={<Accessories />} />
              </Routes>
            </main>
            <Footer />
          </WishlistProvider>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
