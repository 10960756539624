import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import axios from 'axios';

interface BlogPostData {
    title: { rendered: string };
    content: { rendered: string };
    date: string;
}

const BlogPost: React.FC = () => {
    const { postId } = useParams<{ postId: string }>();
    const [post, setPost] = useState<BlogPostData | null>(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://www.leafscreations.net/graphql/posts/${postId}`);
                setPost(response.data);
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };

        fetchPost();
    }, [postId]);

    if (!post) return <Typography>Loading...</Typography>;

    return (
        <Container maxWidth="md">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom>
                    {post.title.rendered}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Published on: {new Date(post.date).toLocaleDateString()}
                </Typography>
                <Box dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            </Box>
        </Container>
    );
};

export default BlogPost;