import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createOrder, processPayment } from '../services/api';
import { RootState } from '../store';
import { clearCart } from '../store/cartSlice';

const Checkout: React.FC = () => {
    const [cardDetails, setCardDetails] = useState({
        number: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
    });
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const order = await createOrder({
                payment_method: 'stripe',
                payment_method_title: 'Credit Card',
                set_paid: false,
                line_items: cartItems.map((item) => ({
                    product_id: item.id,
                    quantity: item.quantity,
                })),
            });

            const payment = await processPayment(order.id, {
                payment_method: 'stripe',
                payment_method_title: 'Credit Card',
                ...cardDetails,
            });

            console.log('Payment processed:', payment);
            dispatch(clearCart());
            // Redirect to thank you page or show confirmation
        } catch (error) {
            console.error('Checkout error:', error);
            // Show error message to user
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={cardDetails.number}
                onChange={(e) => setCardDetails({ ...cardDetails, number: e.target.value })}
                placeholder="Card Number"
            />
            {/* Add inputs for exp_month, exp_year, and cvc */}
            <button type="submit">Pay Now</button>
        </form>
    );
};

export default Checkout;