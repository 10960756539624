import React from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ringSizes = [
    { us: 3, uk: 'F', diameter: '14.1 mm' },
    { us: 4, uk: 'H', diameter: '14.9 mm' },
    { us: 5, uk: 'J', diameter: '15.7 mm' },
    { us: 6, uk: 'L', diameter: '16.5 mm' },
    { us: 7, uk: 'N', diameter: '17.3 mm' },
    { us: 8, uk: 'P', diameter: '18.1 mm' },
    { us: 9, uk: 'R', diameter: '18.9 mm' },
    { us: 10, uk: 'T', diameter: '19.8 mm' },
];

const SizingGuide: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom align="center">
                    Ring Sizing Guide
                </Typography>
                <Typography paragraph>
                    Finding the right ring size is crucial for comfort and security. Use this guide to determine your perfect fit.
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>US Size</TableCell>
                                <TableCell>UK Size</TableCell>
                                <TableCell>Diameter (mm)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ringSizes.map((size) => (
                                <TableRow key={size.us}>
                                    <TableCell>{size.us}</TableCell>
                                    <TableCell>{size.uk}</TableCell>
                                    <TableCell>{size.diameter}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>How to Measure Your Ring Size</Typography>
                    <Typography paragraph>
                        1. Wrap a piece of string or paper around your finger.
                        2. Mark where the ends meet.
                        3. Measure the length in millimeters.
                        4. Compare the measurement to the diameter in the chart above.
                    </Typography>
                    <Box
                        component="img"
                        src="/assets/images/ring-sizer.jpg"
                        alt="Ring size measurement illustration"
                        sx={{ width: '100%', maxWidth: '400px', height: 'auto', display: 'block', margin: '0 auto' }}
                    />
                </Box>
            </Box>
        </Container>
    );
};

export default SizingGuide;