import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_WC_API_URL,
    auth: {
        username: process.env.REACT_APP_WC_CONSUMER_KEY || '',
        password: process.env.REACT_APP_WC_CONSUMER_SECRET || '',
    },
});

export const fetchProducts = async () => {
    const response = await api.get('/products');
    return response.data;
};

export const createOrder = async (orderData: any) => {
    const response = await api.post('/orders', orderData);
    return response.data;
};

export const processPayment = async (orderId: string, paymentData: any) => {
    const response = await api.post(`/payments`, {
        order_id: orderId,
        ...paymentData,
    });
    return response.data;
};