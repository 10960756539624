// src/pages/Account.tsx
import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Account: React.FC = () => {
    const { user, logout } = useAuth();

    if (!user) {
        return (
            <Container maxWidth="md">
                <Typography variant="h4" component="h1" gutterBottom>
                    Account
                </Typography>
                <Typography>Please log in to view your account details.</Typography>
                <Button component={Link} to="/login" variant="contained" color="primary">
                    Log In
                </Button>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Your Account
            </Typography>
            <Box my={4}>
                <Typography variant="h6">Welcome, {user.name}!</Typography>
                <Typography>Email: {user.email}</Typography>
            </Box>
            <Box my={4}>
                <Button component={Link} to="/orders" variant="outlined">
                    View Orders
                </Button>
            </Box>
            <Box my={4}>
                <Button onClick={logout} variant="contained" color="secondary">
                    Log Out
                </Button>
            </Box>
        </Container>
    );
};

export default Account;