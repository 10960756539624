import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid } from '@mui/material';
import AnimatedPage from '../components/AnimatedPage';
import ProductGrid from '../components/ProductGrid';
import Pagination from '../components/Pagination';
import ProductFilters from '../components/ProductFilters';
import { useProducts } from '../hooks/useProducts';
import { Product } from '../types/Product';
import Breadcrumbs from '../components/Breadcrumbs';

const ITEMS_PER_PAGE = 12;

const Category: React.FC = () => {
    const categorySlug = useParams<{ categorySlug: string }>().categorySlug ?? '';
    const { products, loading, error } = useProducts();
    const [page, setPage] = useState(1);
    const [priceRange, setPriceRange] = useState<[number, number]>([0, 1000]);
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

    const categoryProducts = products?.filter(
        (product: Product) => product.category?.toLowerCase() === categorySlug
    ) ?? [];

    const filteredProducts = categoryProducts.filter(
        (product: Product) =>
            product.price >= priceRange[0] &&
            product.price <= priceRange[1] &&
            (selectedMaterials.length === 0 || selectedMaterials.includes(product.material ?? ''))
    );

    const paginatedProducts = filteredProducts.slice(
        (page - 1) * ITEMS_PER_PAGE,
        page * ITEMS_PER_PAGE
    );

    const pageCount = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const materials = Array.from(new Set(categoryProducts.map((product: Product) => product.material ?? '')));

    return (
        <AnimatedPage>
            <Container maxWidth="lg">
                <Breadcrumbs />
                <Typography variant="h4" component="h1" gutterBottom>
                    {categorySlug?.charAt(0).toUpperCase() + categorySlug?.slice(1)}
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <ProductFilters
                            priceRange={priceRange}
                            setPriceRange={setPriceRange}
                            materials={materials}
                            selectedMaterials={selectedMaterials}
                            setSelectedMaterials={setSelectedMaterials}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {loading && <Typography>Loading products...</Typography>}
                        {error && <Typography color="error">Error: {error}</Typography>}
                        {paginatedProducts && <ProductGrid products={paginatedProducts} />}
                        <Pagination count={pageCount} page={page} onChange={handlePageChange} />
                    </Grid>
                </Grid>
            </Container>
        </AnimatedPage>
    );
};

export default Category;
