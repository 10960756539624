// src/components/Breadcrumbs.tsx
import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Breadcrumbs: React.FC = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <MuiBreadcrumbs aria-label="breadcrumb">
            <Link color="inherit" component={RouterLink} to="/">
                Home
            </Link>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                    <Typography color="textPrimary" key={to}>
                        {value}
                    </Typography>
                ) : (
                    <Link color="inherit" component={RouterLink} to={to} key={to}>
                        {value}
                    </Link>
                );
            })}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;