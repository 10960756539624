import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { addToCart } from '../store/cartSlice';
import { Product } from '../types/Product';
import { formatPrice } from '../utils/priceFormatter';

interface ProductCardProps {
    product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    const dispatch = useDispatch();

    const handleAddToCart = () => {
        dispatch(addToCart({
            id: product.id,
            name: product.name,
            price: typeof product.price === 'string' ? parseFloat(product.price) : product.price,
            quantity: 1,
        }));
    };

    return (
        <Card>
            <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    ${formatPrice(product.price)}
                </Typography>
                <Button onClick={handleAddToCart} variant="contained" color="primary">
                    Add to Cart
                </Button>
            </CardContent>
        </Card>
    );
};

export default ProductCard;