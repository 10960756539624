// src/components/ProductFilters.tsx
import React from 'react';
import { Box, Typography, Slider, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

interface ProductFiltersProps {
    priceRange: [number, number];
    setPriceRange: (value: [number, number]) => void;
    materials: string[];
    selectedMaterials: string[];
    setSelectedMaterials: (value: string[]) => void;
}

const ProductFilters: React.FC<ProductFiltersProps> = ({
    priceRange,
    setPriceRange,
    materials,
    selectedMaterials,
    setSelectedMaterials,
}) => {
    const handlePriceChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as [number, number]);
    };

    const handleMaterialChange = (material: string) => {
        setSelectedMaterials(
            selectedMaterials.includes(material)
                ? selectedMaterials.filter((m) => m !== material)
                : [...selectedMaterials, material]
        );
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Filters
            </Typography>
            <Box mb={3}>
                <Typography id="price-range-slider" gutterBottom>
                    Price Range
                </Typography>
                <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="price-range-slider"
                    min={0}
                    max={1000}
                />
            </Box>
            <Box>
                <Typography gutterBottom>Materials</Typography>
                <FormGroup>
                    {materials.map((material) => (
                        <FormControlLabel
                            key={material}
                            control={
                                <Checkbox
                                    checked={selectedMaterials.includes(material)}
                                    onChange={() => handleMaterialChange(material)}
                                />
                            }
                            label={material}
                        />
                    ))}
                </FormGroup>
            </Box>
        </Box>
    );
};

export default ProductFilters;