import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';

const JewelryCare: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom align="center">
                    Jewelry Care Guide
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>Storing Your Jewelry</Typography>
                        <Typography paragraph>
                            Store your pieces separately in a cool, dry place to prevent scratching and tarnishing. Consider using soft cloth pouches or a jewelry box with compartments.
                        </Typography>
                        <Typography variant="h5" gutterBottom>Cleaning Your Jewelry</Typography>
                        <Typography paragraph>
                            Gently clean your jewelry with a soft, lint-free cloth. For more thorough cleaning, use warm water and mild soap, avoiding harsh chemicals that can damage delicate pieces.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src="/assets/images/jewelry-care.jpg"
                            alt="Jewelry care illustration"
                            sx={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'cover' }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default JewelryCare;