import React from 'react';
import { Container, Typography, Box, Grid, Card, CardMedia, CardContent } from '@mui/material';

const materials = [
    { name: 'Tungsten', image: '/images/tungsten.jpg', description: 'Known for its durability and scratch-resistance.' },
    { name: 'Titanium', image: '/images/titanium.jpg', description: 'Lightweight and hypoallergenic.' },
    { name: 'Ceramic', image: '/images/ceramic.jpg', description: 'Scratch-resistant and available in various colors.' },
    { name: 'Carbon Fiber', image: '/images/carbon-fiber.jpg', description: 'Lightweight and modern appearance.' },
    { name: 'Stainless Steel', image: '/images/stainless-steel.jpg', description: 'Affordable and durable.' },
];

const JewelryEducation: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box my={8}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Jewelry Education
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Ring Buying Guide
                </Typography>
                <Typography variant="body1" paragraph>
                    When choosing a ring, it's important to consider the material. Each material has unique properties that affect durability, appearance, and comfort.
                </Typography>
                <Grid container spacing={4}>
                    {materials.map((material) => (
                        <Grid item key={material.name} xs={12} sm={6} md={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={material.image}
                                    alt={material.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {material.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {material.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={4}>
                    <Typography variant="body1" paragraph>
                        We also offer wood inlay options for flat bands, showcasing the natural beauty of various wood types. Additionally, you can choose stone inlays such as turquoise or lab-grown opal for a unique and colorful touch.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default JewelryEducation;