import React from 'react';
import { Container, Typography, Box, TextField, Button, Paper } from '@mui/material';

const Contact: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Contact Us
                </Typography>
                <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                    <form>
                        <TextField fullWidth margin="normal" label="Name" variant="outlined" />
                        <TextField fullWidth margin="normal" label="Email" variant="outlined" type="email" />
                        <TextField fullWidth margin="normal" label="Subject" variant="outlined" />
                        <TextField fullWidth margin="normal" label="Message" variant="outlined" multiline rows={4} />
                        <Button type="submit" variant="contained" color="primary" size="large" sx={{ mt: 2 }}>
                            Send Message
                        </Button>
                    </form>
                </Paper>
            </Box>
        </Container>
    );
};

export default Contact;