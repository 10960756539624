// src/pages/Shop.tsx
import React, { useState } from 'react';
import { Container, Typography, Grid, TextField } from '@mui/material';
import ProductCard from '../components/ProductCard';
import { useProducts } from '../hooks/useProducts';
import { Product } from '../types/Product';

const Shop: React.FC = () => {
    const { products, loading, error } = useProducts({ limit: 12 });
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = products.filter((product: Product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" component="h1" gutterBottom>
                Shop All Products
            </Typography>
            <TextField
                fullWidth
                label="Search products"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                margin="normal"
            />
            <Grid container spacing={4}>
                {filteredProducts.map((product: Product) => (
                    <Grid item key={product.id} xs={12} sm={6} md={4}>
                        <ProductCard product={product} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Shop;