import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Terms of Service
                </Typography>
                <Typography paragraph>
                    Welcome to Leaf's Creations. By using our website and services, you agree to comply with and be bound by the following terms and conditions.
                </Typography>
                {/* Add more content here */}
            </Box>
        </Container>
    );
};

export default TermsOfService;