// src/hooks/useProducts.ts
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Product } from '../types/Product';

interface UseProductsOptions {
    category?: string;
    limit?: number;
}

export const useProducts = (options: UseProductsOptions = {}) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('YOUR_API_ENDPOINT', {
                    params: {
                        category: options.category,
                        per_page: options.limit,
                    },
                });
                setProducts(response.data.map((product: any) => ({
                    id: product.id.toString(),  // Ensure id is converted to string
                    name: product.name,
                    price: product.price.toString(),  // Ensure price is a string
                    image: { sourceUrl: product.images[0]?.src || '' },
                    description: product.description,
                })));
                setLoading(false);
            } catch (err) {
                console.error('Error fetching products:', err);
                setError('Failed to fetch products');
                setLoading(false);
            }
        };

        fetchProducts();
    }, [options.category, options.limit]);

    return { products, loading, error };
};