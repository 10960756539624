import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface BlogPost {
    id: number;
    title: { rendered: string };
    excerpt: { rendered: string };
    featured_media: number;
    _embedded: {
        'wp:featuredmedia': Array<{ source_url: string }>;
    };
}

const Blog: React.FC = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://your-wordpress-site.com/wp-json/wp/v2/posts?_embed');
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return (
        <Container maxWidth="lg">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom align="center">
                    Our Blog
                </Typography>
                <Grid container spacing={4}>
                    {posts.map((post) => (
                        <Grid item key={post.id} xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={post._embedded['wp:featuredmedia'][0].source_url}
                                    alt={post.title.rendered}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {post.title.rendered}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                    <Button component={Link} to={`/blog/${post.id}`} sx={{ mt: 2 }}>
                                        Read More
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default Blog;