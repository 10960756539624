import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';

const About: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom>
                    About Leaf's Creations
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" paragraph>
                            Leaf's Creations is a Veteran and Native American-owned small e-commerce business located in Los Lunas, NM. Founded by John Clegg and Rose (known as Adakair), we specialize in luxury handcrafted jewelry and accessories inspired by the natural beauty of Oregon and New Mexico.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our passion for craftsmanship and design shines through in every piece we create. Each item is meticulously handcrafted, ensuring that no two pieces are exactly alike.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src="/path-to-your-image.jpg" alt="Leaf's Creations workshop" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default About;