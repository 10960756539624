import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const ReturnPolicy: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Return Policy
                </Typography>
                <Typography paragraph>
                    We offer a 30-day return policy for all our products. If you are not satisfied with your purchase, you may return it within 30 days of receiving the item.
                </Typography>
                <Typography paragraph>
                    If the item is damaged, you must ship it back to us. We will cover the cost of return shipping for damaged items.
                </Typography>
                {/* Add more content here */}
            </Box>
        </Container>
    );
};

export default ReturnPolicy;