import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography paragraph>
                    At Leaf's Creations, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.
                </Typography>
                {/* Add more content here */}
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;