// src/components/Footer.tsx
import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(6, 0),
}));

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            About Us
                        </Typography>
                        <Typography variant="body2">
                            Leaf's Creations offers handcrafted jewelry inspired by nature. Each piece is uniquely designed and crafted with care.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2">
                            Email: info@leafscreations.net<br />
                            Phone: (555) 123-4567
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Quick Links
                        </Typography>
                        <Link href="/shop" color="inherit" display="block">Shop</Link>
                        <Link href="/about" color="inherit" display="block">About</Link>
                        <Link href="/blog" color="inherit" display="block">Blog</Link>
                        <Link href="/contact" color="inherit" display="block">Contact</Link>
                    </Grid>
                </Grid>
                <Box mt={5} display="flex" justifyContent="center">
                    <Link href="/privacy-policy" color="inherit" sx={{ mx: 2 }}>Privacy Policy</Link>
                    <Link href="/return-policy" color="inherit" sx={{ mx: 2 }}>Return Policy</Link>
                    <Link href="/terms-of-service" color="inherit" sx={{ mx: 2 }}>Terms of Service</Link>
                </Box>
                <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Leaf's Creations. All rights reserved.
                    </Typography>
                    <Typography variant="body2">
                        Powered by <Link href="https://www.loslunastechnology.com" color="inherit" target="_blank" rel="noopener noreferrer">Los Lunas Technology</Link>
                    </Typography>
                </Box>
            </Container>
        </StyledFooter>
    );
};

export default Footer;