// src/pages/Cart.tsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { RootState } from '../store';
import { removeFromCart, updateQuantity } from '../store/cartSlice';

const Cart: React.FC = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    const handleRemoveItem = (id: string) => {
        dispatch(removeFromCart(id));
    };

    const handleUpdateQuantity = (id: string, newQuantity: number) => {
        dispatch(updateQuantity({ id, quantity: newQuantity }));
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Your Cart
            </Typography>
            {cartItems.length === 0 ? (
                <Typography>Your cart is empty.</Typography>
            ) : (
                <>
                    <List>
                        {cartItems.map((item) => (
                            <ListItem key={item.id}>
                                <ListItemText
                                    primary={item.name}
                                    secondary={`Quantity: ${item.quantity}`}
                                />
                                <Typography>${(item.price * item.quantity).toFixed(2)}</Typography>
                                <Button onClick={() => handleRemoveItem(item.id)}>Remove</Button>
                                <Button onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}>+</Button>
                                <Button onClick={() => handleUpdateQuantity(item.id, Math.max(1, item.quantity - 1))}>-</Button>
                            </ListItem>
                        ))}
                    </List>
                    <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Total: ${total.toFixed(2)}</Typography>
                        <Button component={Link} to="/checkout" variant="contained" color="primary">
                            Proceed to Checkout
                        </Button>
                    </Box>
                </>
            )}
        </Container>
    );
};

export default Cart;