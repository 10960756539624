import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { useProduct } from '../hooks/useProduct';
import { formatPrice } from '../utils/priceFormatter';

const ProductDetail: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    // Ensure productId is passed as an object with the property 'id'
    const { product, loading, error } = useProduct({ id: productId });

    if (!productId) {
        return <Typography color="error">Product ID is missing</Typography>;
    }

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error: {error}</Typography>;
    if (!product) return <Typography>Product not found</Typography>;

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '400px',
                                objectFit: 'cover',
                            }}
                            src={product.image}
                            alt={product.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {product.name}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            ${formatPrice(product.price)}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            {product.description}
                        </Typography>
                        <Button variant="contained" color="primary" size="large">
                            Add to Cart
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ProductDetail;
