import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, InputBase, Box, Drawer, List, ListItem, ListItemText, Popper, Paper, ClickAwayListener } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import styles from '../styles/Header.module.css';
import { useAuth } from '../context/AuthContext';

interface Category {
    name: string;
    path: string;
    subcategories?: Array<{ name: string; path: string }>;
}

interface Resource {
    name: string;
    path: string;
}

const shopCategories: Category[] = [
    {
        name: 'Rings', path: 'rings', subcategories: [
            { name: 'All Rings', path: 'all' },
            { name: 'Men', path: 'men' },
            { name: 'Women', path: 'women' },
            { name: 'Tungsten', path: 'tungsten' },
            { name: 'Black Ceramic', path: 'black-ceramic' },
            { name: 'White Ceramic', path: 'white-ceramic' },
            { name: 'Titanium', path: 'titanium' },
            { name: 'Stainless Steel', path: 'stainless-steel' },
            { name: 'Carbon Fiber', path: 'carbon-fiber' },
        ]
    },
    { name: 'Necklaces', path: 'necklaces' },
    { name: 'Earrings', path: 'earrings' },
    { name: 'Bracelets', path: 'bracelets' },
    { name: 'Pens & Styluses', path: 'pens-and-styluses' },
    { name: 'T-Shirts', path: 't-shirts' },
    { name: 'Accessories', path: 'accessories' },
];

const ringResources: Resource[] = [
    { name: 'Sizing Guide', path: '/sizing-guide' },
    { name: 'How to Choose a Ring', path: '/how-to-choose-a-ring' },
    { name: 'Ring Care Tips', path: '/ring-care-tips' },
    { name: 'Custom Ring Design', path: '/custom-ring' },
];

const aboutItems: Resource[] = [
    { name: 'Our Story', path: '/our-story' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Jewelry Care', path: '/jewelry-care' },
    { name: 'Contact', path: '/contact' },
];

const Header: React.FC = () => {
    const [shopAnchorEl, setShopAnchorEl] = useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleShopClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setShopAnchorEl(shopAnchorEl ? null : event.currentTarget);
    };

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
            setSearchTerm('');
        }
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemText primary="Home" />
                </ListItem>
                {shopCategories.map((category) => (
                    <ListItem button component={Link} to={`/category/${category.path}`} key={category.path}>
                        <ListItemText primary={category.name} />
                    </ListItem>
                ))}
                <ListItem button component={Link} to="/blog">
                    <ListItemText primary="Blog" />
                </ListItem>
                {aboutItems.map((item) => (
                    <ListItem button component={Link} to={item.path} key={item.path}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="static" className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <Link to="/" className={styles.logoLink}>
                    <img src="/logo-white.svg" alt="Leaf's Creations" className={styles.logo} />
                </Link>
                <Box className={styles.navItems}>
                    <Button color="inherit" component={Link} to="/">Home</Button>
                    <Button
                        color="inherit"
                        onClick={handleShopClick}
                        endIcon={<ArrowDropDownIcon />}
                    >
                        Shop
                    </Button>
                    <Button color="inherit" component={Link} to="/blog">Blog</Button>
                    <Button color="inherit" component={Link} to="/about">About</Button>
                    <Button color="inherit" component={Link} to="/contact">Contact</Button>
                </Box>
                <Box className={styles.rightItems}>
                    <form onSubmit={handleSearch} className={styles.searchForm}>
                        <InputBase
                            placeholder="Search..."
                            classes={{
                                root: styles.inputRoot,
                                input: styles.inputInput,
                            }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton type="submit" className={styles.searchButton}>
                            <SearchIcon />
                        </IconButton>
                    </form>
                    <IconButton color="inherit" component={Link} to={user ? "/account" : "/login"}>
                        <AccountCircleIcon />
                    </IconButton>
                    <IconButton color="inherit" component={Link} to="/cart">
                        <ShoppingCartIcon />
                    </IconButton>
                    {user && (
                        <Button color="inherit" onClick={logout}>Logout</Button>
                    )}
                </Box>
                <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)} className={styles.hamburgerMenu}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                    {drawerList}
                </Drawer>
            </Toolbar>
            <Popper open={Boolean(shopAnchorEl)} anchorEl={shopAnchorEl} placement="bottom-start" style={{ width: '100%', maxWidth: '1200px' }}>
                <ClickAwayListener onClickAway={() => setShopAnchorEl(null)}>
                    <Paper className={styles.megaMenu}>
                        <Box display="flex" justifyContent="space-between">
                            {shopCategories.map((category) => (
                                <Box key={category.path} className={styles.megaMenuColumn}>
                                    <h3 className={styles.megaMenuTitle}>{category.name}</h3>
                                    {category.subcategories ? (
                                        <>
                                            <Link to={`/category/${category.path}`} className={styles.megaMenuItem}>
                                                All {category.name}
                                            </Link>
                                            {category.subcategories.map((subcategory) => (
                                                <Link
                                                    key={subcategory.path}
                                                    to={`/category/${category.path}/${subcategory.path}`}
                                                    className={styles.megaMenuItem}
                                                >
                                                    {subcategory.name}
                                                </Link>
                                            ))}
                                        </>
                                    ) : (
                                        <Link to={`/category/${category.path}`} className={styles.megaMenuItem}>
                                            View All
                                        </Link>
                                    )}
                                </Box>
                            ))}
                            <Box className={styles.megaMenuColumn}>
                                <h3 className={styles.megaMenuTitle}>Ring Resources</h3>
                                {ringResources.map((resource) => (
                                    <Link key={resource.path} to={resource.path} className={styles.megaMenuItem}>
                                        {resource.name}
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </AppBar>
    );
};

export default Header;