// src/components/Hero.tsx

import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
    return (
        <Box
            sx={{
                mt: -8, // Equivalent to -64px as 1rem = 8px in Material-UI
                position: 'relative',
                height: '50vh',
                backgroundImage: {
                    xs: 'url(https://imagedelivery.net/YOYLlV9k9-cgtoT17bkdqw/5e16e7a8-f55a-4f59-d30a-47628446fc00/WPportraitSmall)',
                    md: 'url(https://imagedelivery.net/YOYLlV9k9-cgtoT17bkdqw/5e16e7a8-f55a-4f59-d30a-47628446fc00/desktoplandscape)'
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }
            }}
        >
            <Container maxWidth="sm" sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="common.white"
                    gutterBottom
                >
                    Handcrafted Jewelry
                </Typography>
                <Typography variant="h5" align="center" color="common.white" paragraph>
                    Discover unique, artisanal pieces crafted with passion and care.
                </Typography>
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" component={Link} to="/shop" size="large">
                        Shop Now
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default Hero;
