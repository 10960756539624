import React from 'react';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const materials = [
    { name: 'Gold', description: 'Gold is a precious metal with a bright yellow color.' },
    { name: 'Silver', description: 'Silver is a shiny white metal with high reflectivity.' },
    { name: 'Platinum', description: 'Platinum is a dense, malleable, and ductile metal.' },
    { name: 'Titanium', description: 'Titanium is a strong, lightweight metal with a silver color.' },
    { name: 'Carbon Fiber', description: 'Carbon fiber is a strong, lightweight material with a unique pattern.' },
    { name: 'Wood', description: 'Wood adds a natural, rustic look to any ring.' },
];

const Materials: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 5 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Materials We Use
                </Typography>
                <Grid container spacing={4}>
                    {materials.map((material) => (
                        <Grid item xs={12} md={4} key={material.name}>
                            <Box sx={{ border: '1px solid #ccc', p: 3 }}>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    {material.name}
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {material.description}
                                </Typography>
                                <Button variant="contained" color="primary" component={Link} to="/custom-ring">
                                    Customize a Ring
                                </Button>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default Materials;
