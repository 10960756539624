import React from 'react';
import { Container, Typography, Grid, Box, Button, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import { useProducts } from '../hooks/useProducts';
import ProductCard from '../components/ProductCard';
import CategoryShowcaseAlt from '../components/CategoryShowcaseAlt';

const Home: React.FC = () => {
    const { products, loading, error } = useProducts({ limit: 4 });

    return (
        <React.Fragment>
            <SEO
                title="Handcrafted Jewelry"
                description="Discover unique, artisanal jewelry pieces crafted with passion at Leaf's Creations."
                keywords="handmade jewelry, artisanal, unique designs"
            />
            <Hero />
            <Container maxWidth="lg">
                <Box sx={{ my: 5 }}>
                    <Typography variant="h3" gutterBottom>Our Collections</Typography>
                    <CategoryShowcaseAlt />
                </Box>

                <Box sx={{ my: 5 }}>
                    <Typography variant="h3" gutterBottom>Featured Products</Typography>
                    <Grid container spacing={4}>
                        {loading && <Typography>Loading...</Typography>}
                        {error && <Typography color="error">{error}</Typography>}
                        {products && products.map((product) => (
                            <Grid item key={product.id} xs={12} sm={6} md={3}>
                                <ProductCard product={product} />
                            </Grid>
                        ))}
                    </Grid>
                    <Box mt={4} display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" component={Link} to="/shop">
                            View All Products
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ my: 5 }}>
                    <Typography variant="h3" gutterBottom>The Art of Handmade Jewelry</Typography>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" paragraph>
                                At Leaf's Creations, we believe that jewelry is more than just an accessory – it's a form of art that tells a unique story. Each piece is meticulously crafted by hand, ensuring that no two items are exactly alike.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our artisans pour their heart and soul into every creation, using traditional techniques passed down through generations alongside modern design concepts. This fusion of old and new results in jewelry that is both timeless and contemporary.
                            </Typography>
                            <Typography variant="body1">
                                When you choose a handmade piece from Leaf's Creations, you're not just wearing jewelry – you're wearing a unique work of art, crafted with passion and care.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '400px',
                                    objectFit: 'cover',
                                }}
                                src="/assets/images/lathe-ring-making-handmade.jpg"
                                alt="Handmade jewelry process"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ my: 5 }}>
                    <Typography variant="h3" gutterBottom>Accessories & Apparel</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardActionArea component={Link} to="/category/pens-and-styluses">
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        image="/assets/images/pens/wooden-pen–exotic–authentic-bethlehem-olive.jpg"
                                        alt="Pens and Styluses"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Pens & Styluses
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardActionArea component={Link} to="/category/t-shirts">
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        image="/assets/images/tshirts/tshirt-zia-symbol-hummingbird-Resized.jpg"
                                        alt="T-Shirts"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            T-Shirts
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ my: 5 }}>
                    <Typography variant="h3" gutterBottom>From Our Blog</Typography>
                    <Grid container spacing={4}>
                        {blogPosts.map((post) => (
                            <Grid item key={post.id} xs={12} md={4}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={post.image}
                                        alt={post.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {post.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {post.excerpt}
                                        </Typography>
                                        <Button component={Link} to={`/blog/${post.id}`} sx={{ mt: 2 }}>
                                            Read More
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
};

const blogPosts = [
    {
        id: 1,
        title: "The Magic of Opal: October's Mystical Birthstone",
        image: "/assets/images//blog/blog-uncut-opal-stone.jpg",
        excerpt: "Dive into the mesmerizing world of opals, nature's rainbow gem. Discover the legends, care tips, and why this stone captivates jewelry lovers worldwide."
    },
    {
        id: 2,
        title: "Sustainable Jewelry: Our Commitment to the Planet",
        image: "/assets/images/blog/blog-handmade-necklace-turquoise.jpg",
        excerpt: "Explore how we're making a difference with eco-friendly practices, recycled metals, and ethically sourced gemstones. Your beauty shouldn't cost the Earth."
    },
    {
        id: 3,
        title: "Autumn Inspirations: New Collection Sneak Peek",
        image: "/assets/images//blog/blog-earrings-spiral-brown.jpg",
        excerpt: "Get a first look at our upcoming fall collection. Warm hues, nature-inspired designs, and a touch of magic – perfect for the changing seasons."
    }
];

export default Home;