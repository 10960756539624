import React from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

interface Category {
    name: string;
    image: string;
    description: string;
}

const categories: Category[] = [
    { name: 'Rings', image: '/assets/images/rings/ring-stainless-steel-sleeping-beauty-turquoise.jpg', description: 'Elegant rings for every occasion' },
    { name: 'Necklaces', image: '/assets/images/necklaces/necklace-peaceful-amethyst-turquoise-amethyst-hematite.jpg', description: 'Beautiful necklaces to complement your style' },
    { name: 'Earrings', image: '/assets/images/earrings/earrings-spiral-sunshine.jpg', description: 'Stunning earrings for a perfect finish' },
    { name: 'Bracelets', image: '/assets/images/bracelets/bracelet-dragonfly-navajo ghost-beads-juniper-Malachite-beads.jpg', description: 'Charming bracelets to adorn your wrist' },
];

const CategoryShowcaseAlt: React.FC = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
                {categories.map((category, index) => (
                    <Grid item key={category.name} xs={12} sm={6} md={index % 3 === 0 ? 8 : 4}>
                        <Card>
                            <CardActionArea component={Link} to={`/category/${category.name.toLowerCase()}`}>
                                <CardMedia
                                    component="img"
                                    height={index % 3 === 0 ? '400' : '300'}
                                    image={category.image}
                                    alt={category.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {category.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {category.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CategoryShowcaseAlt;