import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqItems = [
    {
        question: "What makes Leaf's Creations jewelry unique?",
        answer: "Our jewelry is handcrafted with care, inspired by nature, and created using sustainable practices. Each piece is one-of-a-kind, reflecting the artistry and passion of our skilled craftspeople."
    },
    {
        question: "How do I care for my handmade jewelry?",
        answer: "We recommend storing your jewelry in a cool, dry place and avoiding exposure to chemicals like perfumes or cleaning products. For specific care instructions, please visit our Jewelry Care page."
    },
    {
        question: "Do you offer custom designs?",
        answer: "Yes, we love creating custom pieces! Please contact us with your ideas, and we'll work with you to bring your vision to life."
    },
    {
        question: "What is your return policy?",
        answer: "We offer a 30-day return policy for unworn items in their original condition. Custom pieces are non-refundable. Please see our Returns page for more details."
    },
    {
        question: "How long does shipping take?",
        answer: "Domestic orders typically ship within 3-5 business days. International shipping times vary. You'll receive a tracking number once your order is dispatched."
    },
    {
        question: "Are your materials ethically sourced?",
        answer: "Yes, we are committed to using ethically sourced materials. Our gemstones and metals come from reputable suppliers who adhere to responsible mining practices."
    },
];

const FAQ: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={8}>
                <Typography variant="h2" component="h1" gutterBottom align="center">
                    Frequently Asked Questions
                </Typography>
                {faqItems.map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQ;