import React from 'react';
import { Container, Typography, Grid, Box, Button, TextField, MenuItem } from '@mui/material';

const materials = ['Gold', 'Silver', 'Platinum', 'Titanium', 'Carbon Fiber', 'Wood'];

const CustomRing: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 5 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Customize Your Ring
                </Typography>
                <form>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                label="Choose Material"
                                variant="outlined"
                                margin="normal"
                            >
                                {materials.map((material) => (
                                    <MenuItem key={material} value={material}>
                                        {material}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                label="Ring Size"
                                variant="outlined"
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Engraving Text"
                                variant="outlined"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Additional Notes"
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" color="primary" size="large">
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default CustomRing;
